import gql from 'graphql-tag';
import { getAuthToken } from 'helper/user'
import { Category } from 'models/Category'

export const GET_CATEGORIES = gql`
  query getCategories($shopId: String) {
    categories(shopId: $shopId)
    @rest(type: "Category[]",
          method: "GET",
          path: "/shop/{args.shopId}/category",
          endpoint: "ss") {
      id
      name
      prettyName
      parentId
      containsAlcohol
      containsTobacco
      isFlammable
      isDisplayed
      defaultThumbnailUrl
      defaultImageUrl
      vatCode
      productsCount
    }
  }
`;

type GetCategoriesResponse = Category[];

export const getCategories = (shopUuid: string): Promise<GetCategoriesResponse> => {
  return new Promise((resolve, reject) => {
    let url = new URL(`${process.env.API_SHOP_SERVICE}/shop/${shopUuid}/category`);
    fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Devo-Api-Key': process.env.DEVO_API_KEY,
        'Devo-Auth-Token': getAuthToken(),
      },
    })
      .then(res => res.json())
      .then(resolve)
      .catch(err => reject(err));
  });
};